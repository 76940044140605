import type { TypeCMSUser } from '@/schema'

type TypeUserCookie = {
  token: string,
  exp: string,
  user: TypeCMSUser
}

export const navLinksByRole = {
  'admin': ['invoices'],
}

export const getUserDataFromCookie = (userCookieValue:string):TypeUserCookie => JSON.parse(userCookieValue)
