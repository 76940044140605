import { NextResponse } from 'next/server'
import type { NextRequest } from 'next/server'

export const checkIfPublicPath = (pathname) => {
  if (pathname.startsWith('/login') ||
      pathname.startsWith('/signup') ||
      pathname.startsWith('/reset-password')) {
    return true
  }
}

export const checkIfLegalPath = (pathname) => {
  if (pathname.startsWith('/rgpd') ||
      pathname.startsWith('/use-conditions') ||
      pathname.startsWith('/privacy-policy')) {
    return true
  }
}

// This function can be marked `async` if using `await` inside
export function middleware(request: NextRequest) {
  let userCookie = request.cookies.get('DY_VIRBAC_XML_USER')
  let isPublicPath = checkIfPublicPath(request.nextUrl.pathname)
  // console.log('🟣 userCookie', userCookie?.name)

  if(isPublicPath && userCookie) {
    return NextResponse.redirect(new URL('/', request.url))
  } else if(!isPublicPath && !userCookie) {
    console.log('❌ !isPublicPath && !userCookie >>> AL LOGIN QUE NO HAY COOKIE request.nextUrl >>>', request.nextUrl)
    return NextResponse.redirect(new URL('/login', request.url))
  }
}

export const config = {
  matcher: [
    /*
     * Match all request paths except for the ones starting with:
     * - api (API routes)
     * - _next/static (static files)
     * - _next/image (image optimization files)
     * - favicon.ico (favicon file)
     */
    '/((?!api|_next/static|_next/image|favicon.ico|images).*)',
  ],
}
