import { createContext, useState, useEffect } from 'react'
import type { FC, ReactNode } from 'react'

export enum MODALS {
  CLIPBOARD='modal_clipboard',
  EMAIL='modal_email',
  INVITE_VET_EMAIL='modal_invite_vet_email',
  MENU = 'modal_menu',
  QR='modal_qr',
  RECOMMENDATION='modal_recommendation',
  SMS='modal_sms',
  FORGOT_PASSWORD='modal_forgot_password',
}

export interface iDefaultModal { isActive: boolean, payload?: any }

// interface iModalAuthPayload {
//   payload: {
//     level: string
//   }
// }

type TypeModalException = {
  // [MODALS.AUTH]: iModalAuthPayload
}

// Utility to merge two Definitions: B object properties shadowing -> A object properties
type $Merge<TA, TB> = Omit<TA, keyof TB> & TB;
// Getting the Base+Extended type of an Modal
type TypeModalById<A extends MODALS> = A extends keyof TypeModalException ? $Merge<iDefaultModal, TypeModalException[A]> : iDefaultModal


type TypeModals = {
  [key in MODALS]?: TypeModalById<key>
}

type TypeSetModal = (modal: TypeModals) => void
type TypeState = {
  modals: TypeModals,
  setModal: TypeSetModal,
  someModalIsActive: boolean
}

const initialState: TypeState = {
  modals: {},
  setModal: () => { },
  someModalIsActive: false
}

type Props = {
  children: ReactNode
}

export const ModalsContext = createContext<TypeState | null>(initialState)

export const ModalsProvider:FC<Props> = ({ children }) => {
  const [modals, setModals] = useState<TypeModals>({})
  const [someModalIsActive, setSomeModalIsActive] = useState(false)

  const setModal: TypeSetModal = (modal) => {
    setModals(prevModals => ({ ...prevModals, ...modal }))
    let someActive = false

    const [modalKeyname, modalData] = Object.entries(modal)[0]
    if (modalData?.isActive) someActive = true
    else {
      for (const [mKeyname, m] of Object.entries(modals)) {
        // Object.entries(modals) still have the old state for the current modal
        // so we only check if any other modal isActive
        if (m.isActive && modalKeyname !== mKeyname) {
          someActive = true
          break
        }
      }
    }

    setSomeModalIsActive(someActive)
  }

  useEffect(() => {
    document.body.style.overflow = someModalIsActive ? 'hidden' : 'initial'
    document.body.style.height = someModalIsActive ? '100vh' : 'unset'
  }, [someModalIsActive])

  return (<ModalsContext.Provider value={{ modals, setModal, someModalIsActive }}>
    {children}
  </ModalsContext.Provider>)
}
