const altByIcon = {
  'edit': 'Edit icon',
  'bin': 'Bin icon',
  'invoices': 'Invoices icon',
  'dashboard': 'Gear icon',
  'invite': 'question mark icon',
  'password-eye': 'Password eye icon',
  'recommendations': 'Recommendations icon',
  'logout': 'Logout icon',
  'legal': 'Legal icon',
  'search': 'Search glass icon',
  'search-clear': 'Clear search icon',
}

export type TypeMapIcon = keyof typeof altByIcon
export const iconByName = (iconName: TypeMapIcon) => ({
  type: 'svg' as const,
  src: `/images/svg/i--${iconName}.svg`,
  alt: altByIcon[iconName],
  size: { width: 24, height: 24 }
})
