'use client'
import { memo, useMemo } from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import styled from 'styled-components'
import { mq, hex2Rgba, getP12 } from '@/styles'

type innerLink = {
  text: string,
  hash?: string
  param?: {
    pathname: string,
    name: string,
    value: string | number | null | boolean
  }
}
interface IInnerNavigation {
  links: innerLink[]
}

const getLinks = (links, pathname) => links.map((link, idx) => {
  if (link.hash) {
    return (
      <li key={idx}>
        <Link href={link.hash} className={pathname === link.hash ? 'active' : ''}>{link.text}</Link>
      </li>
    )
  }
})

export const InnerNavigation = memo(({ links }: IInnerNavigation) => {
  const pathname = usePathname()
  const Links = useMemo(() => getLinks(links, pathname), [links, pathname])

  return (
    <Ol className='inner-navigation'>
      {Links}
    </Ol>
  )
})

const Ol = styled.ol`
  display: none;

  ${mq.greaterThan('tablet')} {
    align-items: center;
    background-color: inherit;
    display: flex;
    flex-wrap: nowrap;
    grid-column: 6 / span 7;
    height: 60px;
    justify-content: center;
    position: sticky;
  }

  li {
    height: 100%;
    width: auto;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    ${getP12()}
    align-items: center;
    border-bottom: 3px solid ${({ theme }) => hex2Rgba(theme.colors.text, 0)};
    color: ${({ theme }) => hex2Rgba(theme.colors.text, .5)};
    display: flex;
    height: 100%;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 300ms border-color ease-in-out, 300ms color ease-in-out;

    &.active,
    &:hover {
      border-color: var(--color-blue);
      color: var(--color-text);
    }
  }
`
