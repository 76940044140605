// import { PRO, DEV, HOSTNAME } from './index'
const HOSTNAME = process.env.NEXT_PUBLIC_PROJECT_HOSTNAME
const DEV = process.env.NEXT_PUBLIC_VERCEL_ENV === 'development'
const PRO = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

const HOST = HOSTNAME.split('staging.')
const DOMAIN = HOST[HOST.length - 1]

interface CookieSerializeOptions {
  domain?: string;
  encode?(value: string): string;
  expires?: Date;
  httpOnly?: boolean;
  maxAge?: number;
  path?: string;
  sameSite?: true | false | 'lax' | 'strict' | 'none';
  secure?: boolean;
}

export const cookie = {
  set: (name: string, value:string = '', options: CookieSerializeOptions = {}): void => {
    const { domain, expires, httpOnly, maxAge, path, sameSite } = options
    console.log(process.env.NEXT_PUBLIC_VERCEL_ENV)
    let cookieString = `${name}=${value}`

    cookieString += `; domain=${DEV ? 'localhost' : domain ? domain : DOMAIN}`
    if (expires) cookieString += `; expires=${expires.toUTCString()}`
    if (httpOnly) cookieString += '; HttpOnly'
    if (maxAge !== undefined) cookieString += `; max-age=${maxAge}`
    cookieString += `; path=${path ?? '/'}`
    if (sameSite) cookieString += `; samesite=${sameSite}`
    if (PRO) cookieString += '; Secure'

    document.cookie = cookieString
  },
  get: (name: string): string | null => {
    const allCookies = document.cookie.split(';')

    for (const cookie of allCookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=')
      if (cookieName === name) return decodeURIComponent(cookieValue)
    }

    console.warn(`cookie with name: '${name}' not found.`)
    return null
  },
  remove: (name: string) => {
    if (DEV) document.cookie = name + '=; Max-Age=-99999999; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/;'
    else {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; domain=.${DOMAIN}`
    }
  }
}
