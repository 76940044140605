export const LOCALE = process.env.NEXT_PUBLIC_LOCALE
export const HOSTNAME = process.env.NEXT_PUBLIC_PROJECT_HOSTNAME
export const DEV = process.env.NEXT_PUBLIC_VERCEL_ENV === 'development'
export const STAGING = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
export const PRO = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

export * from './shuffle'
export * from './slugify'
export * from './icons'
export * from './sortBy'
export * from './cookie'
export * from './inputAutoComplete'
export * from './inputValidations'
export * from './inputPostalCodeValidations'
export * from './inputPatterns'
export * from './virbac'
export * from './pdf_downloader'
export * from './xls_downloader'
export * from './xml_downloader'
export * from './copy_clipboard'
