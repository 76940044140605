'use client'
import { memo, type FC, type ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { mq, designGrid, hex2Rgba, getP20 } from '@/styles'
import { useSession, useTranslator } from '@/hooks'
import { ButtonGoBack } from './ButtonGoBack'

type typePage = 'account' | 'invoices' | 'invoice' | 'legal'
interface Props {
  children?: ReactNode
  hasTabs?: boolean
  page?: typePage
  name?: string
}

const getTitle = (t, page, user, name) => {
  const title = t(`layout.header.${page}.title`)
  const titleDynamic = t(`layout.header.${page}.title_dynamic`)
  const goBack = t(`layout.header.${page}.go_back`)

  if (typeof title === 'string' && !titleDynamic) {
    return <h1>{goBack && <ButtonGoBack />}{title}{name && name}</h1>
  } else {
    if(!user) return null
    switch(page) {
      default:
        return null
    }
  }
}

export const Header: FC<Props> = memo(({ children, hasTabs = false, page, name }) => {
  const { t } = useTranslator()
  const { user } = useSession()
  const H1 = useMemo(() => getTitle(t, page, user, name), [t, page, user, name])

  return (
    <HeaderStyled $hasTabs={hasTabs}>
      {H1}
      {children && children}
    </HeaderStyled>
  )
})

const HeaderStyled = styled.header<{ $hasTabs: boolean }>`
  ${designGrid({})}
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.background_white};
  border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.text, .1)};
  height: ${({ $hasTabs }) => $hasTabs ? 120 : 60}px;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 10;

  h1 {
    ${getP20}
    align-items: center;
    display: flex;
    grid-column: 2 / span 6;
    height: 60px;
    margin-left: 10px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${mq.greaterThan('tablet')} {
      grid-column: 1 / span 12;
      margin-left: 0;
    }
  }

  ol {
    grid-column: ${({ $hasTabs }) => $hasTabs ? '1 / span 12' : '6 / span 7'};
    grid-row: ${({ $hasTabs }) => $hasTabs ? '2 / span 1' : '1 / span 1'};
    justify-content: ${({ $hasTabs }) => $hasTabs ? 'flex-start' : 'center'};
  }
`
