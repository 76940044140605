import type { FC, ReactNode, Dispatch, SetStateAction } from 'react'
import { createContext, useState, useEffect } from 'react'
import type { TypeCMSUser } from '@/schema'
import { cookie, getUserDataFromCookie } from '@/utils'
import { usePathname, useRouter } from 'next/navigation'
import { checkIfPublicPath, checkIfLegalPath } from '@/middleware'


type TypeUser = TypeCMSUser & { token: string }
type TypeState = {
  user: TypeUser
  setUser: Dispatch<SetStateAction<TypeUser>>
}

const initialState: TypeState = {
  user: null,
  setUser: () => { },
}

export const SessionContext = createContext<TypeState | null>(initialState)

interface Props {
  children: ReactNode
}

// let cookieChecked = false
export const SessionProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<TypeUser>(undefined)
  const { push } = useRouter()
  const pathname = usePathname()

  useEffect(() => {
    const userCookieData = getUserDataFromCookie(cookie.get('DY_VIRBAC_XML_USER'))

    if (!userCookieData) {
      if (!checkIfPublicPath(pathname) && !checkIfLegalPath(pathname)) push('/login')
    } else {
      console.log('userCookieData', userCookieData)
      setUser({ ...userCookieData.user, token: userCookieData.token })
    }

    // cookieChecked = true
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <SessionContext.Provider value={{ user, setUser }}>{children}</SessionContext.Provider>
}
