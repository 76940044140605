'use client'
import { useState } from 'react'
import type { ChangeEvent } from 'react'
import type { UseFormRegister as TypeRegister, FieldError } from 'react-hook-form'
import styled from 'styled-components'
import { noRegister, type TypeThemeColors } from './Input'
import { mq, vw, getP16, type Col } from '@/styles'

type onChangeType = (event: ChangeEvent<HTMLInputElement>) => void

interface iCheckbox {
  readonly name: string,
  readonly label: string,
  defaultChecked?: boolean,
  readonly required?: boolean,
  readonly disabled?: boolean,
  readonly readOnly?: boolean | null,
  readonly onChange?: onChangeType,
  readonly col?: Col
  readonly register?: TypeRegister<any>
  error?: FieldError | any,
  readonly textColor?: TypeThemeColors
  readonly bgColor?: TypeThemeColors
  readonly switchAppearance?: boolean
}

interface iLabelProps {
  $col: Col
  $textColor?: TypeThemeColors
  $bgColor: TypeThemeColors
  $checked: boolean
}

export const Checkbox = ({ name, label, defaultChecked = false, required = false, disabled = false, readOnly = null, onChange = null, col = 12, register = noRegister, textColor = 'text', bgColor = 'background', switchAppearance = false, error }: iCheckbox): JSX.Element => {
  const requireProps = typeof required === 'boolean' ? { required } : required
  const { onChange: registerOnChange, name: registerName, ref: registerRef } = register(name, { ...requireProps })
  const [checked, setChecked] = useState(defaultChecked)

  const handleChange = e => {col
    registerOnChange && registerOnChange(e)
    onChange && onChange(e)
    setChecked(e.target.checked)
  }

  return (
    <Label $col={col} $bgColor={bgColor} $textColor={textColor} $checked={checked}>
      <input {...registerRef && { ref: registerRef }} type='checkbox' name={registerName ?? name} required={required} readOnly={readOnly} disabled={disabled} onChange={handleChange} aria-invalid={!!error} {...defaultChecked && { defaultChecked: checked }}
      />
      {switchAppearance ? <Switch $checked={checked} /> : <TickBox $checked={checked} />}
      <span>{label}</span>
    </Label>
  )
}


const Label = styled.label<iLabelProps>`
  align-items: flex-start;
  color: ${({ theme, $textColor }) => theme.colors[$textColor]};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${vw(20, 'mobile')};
  padding: ${vw(4.25, 'mobile')} ${vw(4.25, 'mobile')} 0;
  position: relative;
  width: auto;

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(20, 'nexus7')};
    padding: ${vw(4.25, 'nexus7')} ${vw(4.25, 'nexus7')} 0;
  }

  ${mq.greaterThan('tablet') } {
    margin-bottom: ${vw(30, 'desktop')};
    padding: ${vw(12.5, 'desktop')} ${vw(12.5, 'desktop')} 0;
    width: ${({ $col }) => typeof $col === 'number' ? `${100 * $col / 12}%` : $col};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 30px;
    padding: 12px 12px 0;
  }

  > div {
    border-color: ${({ theme }) => theme.colors['blue']};
  }

  input {
    opacity: 0;
    position: absolute;
    user-select: none;
    visibility: hidden;
  }

  &:hover {
    input + div {
      opacity: .8;
    }
  }

  span {
    ${getP16()}
    display: inline-block;
    margin-top: 3px;
    user-select: none;
  }
`

const TickBox = styled.div<{ $checked: boolean }>`
  background-color: ${({ theme, $checked }) => $checked ? theme.colors['blue'] : 'transparent'};
  border: 1px solid;
  border-radius: 8px;
  flex-shrink: 0;
  height: 16px;
  margin-right: ${vw(10, 'mobile')};
  margin-top: 5px;
  position: relative;
  transition: 250ms background-color linear, 250ms opacity linear;
  user-select: none;
  width: 16px;

  ${mq.greaterThan('nexus7')} {
    margin-right: ${vw(10, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    margin-right: ${vw(10, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-right: 10px;
  }
`

const Switch = styled.div<{ $checked: boolean }>`
  align-items: center;
  background: ${({ theme, $checked }) => theme.colors[$checked ? 'blue' : 'grey']};
  border-radius: 9.5px;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 17px;
  margin-right: ${vw(20, 'nexus7')};
  margin-top: 5px;
  padding: 0 1px;
  position: relative;
  transition: 250ms background-color linear, 250ms opacity linear;
  width: 30px;

  ${mq.greaterThan('nexus7')} {
    margin-right: ${vw(20, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    margin-right: ${vw(20, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-right: 20px;
  }

  &:after {
    background: var(--color-white);
    border-radius: 50%;
    content: '';
    height: 15px;
    margin-left: ${({ $checked }) => $checked ? '15px' : 0};
    transition: 250ms margin-left linear;
    width: 15px;
  }
`
